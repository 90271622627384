import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../../components/Layout";
import {Helmet} from "react-helmet";

const ReleasesPage = ({data, count}) => {
  const {edges: posts} = data.allMarkdownRemark;

  return (
    <Layout title="Releases">
      <Helmet>
        <title>76666 - RELEASES</title>
        <meta
          name="description"
          content="76666 Audio Releases"
        />
      </Helmet>
      <div className="plain-grid">
        {posts.map(post => (
          <Link
            to={post.node.fields.slug} key={post.node.fields.slug}
          >
            <div
              className="plain-grid-img"
              aria-label={`${post.node.frontmatter.artist} - ${post.node.frontmatter.title}`}
            >
              <Img
                fluid={post.node.frontmatter.coverimage.childImageSharp.fluid}
                alt={`Cover of release "${post.node.frontmatter.title}"`}
              />
              <div className="release-title">{post.node.frontmatter.artist} - {post.node.frontmatter.title}</div>
            </div>
          </Link>)
        )}
      </div>
    </Layout>

  );
};


export default () => (
  <StaticQuery
    query={graphql`
      query ReleaseGridQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter:  {frontmatter: {category: {eq: "releases"}}}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                artist
                coverimage {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ReleasesPage data={data} count={count}/>}
  />
)

